<template>
  <a-spin :spinning="page_loading">
    <sdPageHeader title="Anlaşmalı Kargo" />
    <Main>
      <a-button
        type="primary"
        style="position: absolute;top: 30px;right: 30px"
        @click="show_create_form = true"
      >
        <sdFeatherIcons
          size="12"
          type="plus"
          style="color:#ffffff;margin-right: 2px;"
        />
        Kargo Ekle
      </a-button>
      <a-row
        v-if="configured_shipping_companies.length === 0"
        type="flex"
        justify="center"
        :gutter="12"
        align="top"
      >
        <a-col :span="16">
          <!--          asd-->
          <a-button
            type="dashed"
            ghost
            block
            @click="show_create_form = true"
          >
            Kargo Bulunamadı <span style="color:#5f63f2;font-weight: bold"> Şimdi Ekle</span>
          </a-button>
        </a-col>
        <a-col :span="16">
          <img
            alt="example"
            src="@/img/company-not-found.png"
            style="width: 100%;"
          >
        </a-col>
      </a-row>
      <a-row
        v-else
        type="flex"
        justify="start"
        :gutter="12"
        align="top"
      >
        <a-col
          v-for="company in configured_shipping_companies"
          :key="company.id"
          :span="6"
          style="margin-bottom: 10px"
        >
          <a-spin :spinning="edit_form_open_loadings[company.id] === true">
            <a-card
              hoverable
              @click="editCargoModalForm(company.id)"
            >
              <template #cover>
                <img
                  alt="example"
                  :src="require('@/img/' + company.code+'-logo.png')"
                >
              </template>
              <a-card-meta>
                <template #title>
                  <div class="text-center">
                    {{ company.title }} ({{ company.is_active ? 'Aktif' : 'Pasif' }})
                  </div>
                </template>
              </a-card-meta>
            </a-card>
          </a-spin>
        </a-col>
      </a-row>
    </Main>
  </a-spin>

  <a-modal
    v-model:visible="show_create_form"
    title="Ekleme"
    :confirm-loading="create_form_submit_loading"
    ok-text="Kaydet"
    cancel-text="İptal"
    @ok="handleCreateCompany"
    @cancel="show_create_form = false"
  >
    <a-spin :spinning="create_form_open_loading">
      <a-form layout="vertical">
        <a-form-item
          id="title"
          label="Kargo Adı"
        >
          <a-input
            id="title"
            v-model:value="create_form_state.title"
          />
        </a-form-item>
        <a-form-item
          id="discount_rate"
          label="İndirim Oranı (%20 için 20 olarak giriniz)"
        >
          <a-input
            id="discount_rate"
            v-model:value="create_form_state.discount_rate"
          />
        </a-form-item>
        <a-form-item
          id="can_cod"
          label="Tahsilatlı Gönderi Oluşturulabilir?"
        >
          <a-checkbox
            id="can_cod"
            v-model:checked="create_form_state.can_cod"
          />
        </a-form-item>
        <a-form-item
          id="is_active"
          label="Müşteriler tarafından kullanılabilsin mi?"
        >
          <a-checkbox
            id="is_active"
            v-model:checked="create_form_state.is_active"
          />
        </a-form-item>
        <a-form-item
          id="code"
          label="Kargo Firması Seçiniz"
        >
          <a-select
            id="code"
            v-model:value="create_form_state.code"
            default-value="Firma Seçiniz"
            size="large"
            name="code"
            class="sDash_fullwidth-select"
            @change="handleCompanyChange()"
          >
            <a-select-option
              v-for="item in shipping_company_modules"
              :key="item.code"
              :value="item.code"
            >
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="item in create_form_state.form_options"
          :id="item.code"
          :key="item.code"
          :label="item.label"
        >
          <a-select
            v-if="item.type === 'select'"
            :id="item.code"
            v-model:value="create_form_state.form_data[item.code]"
            size="large"
            class="sDash_fullwidth-select"
          >
            <a-select-option
              v-for="option in item.options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.label }}
            </a-select-option>
          </a-select>
          <a-input
            v-else
            :id="item.code"
            v-model:value="create_form_state.form_data[item.code]"
            :placeholder="item.label"
          />
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>

  <a-modal
    v-model:visible="show_edit_form"
    title="Düzenle"
  >
    <a-form layout="vertical">
      <a-form-item
        id="title"
        label="Kargo Adı"
      >
        <a-input
          id="title"
          v-model:value="edit_form_state.title"
        />
      </a-form-item>
      <a-form-item
        id="discount_rate"
        label="İndirim Oranı (%20 için 20 olarak giriniz)"
      >
        <a-input
          id="discount_rate"
          v-model:value="edit_form_state.discount_rate"
        />
      </a-form-item>
      <a-form-item
        id="can_cod"
        label="Tahsilatlı Gönderi Oluşturulabilir?"
      >
        <a-checkbox
          id="can_cod"
          v-model:checked="edit_form_state.can_cod"
        />
      </a-form-item>
      <a-form-item
        id="is_active"
        label="Müşteriler tarafından kullanılabilsin mi?"
      >
        <a-checkbox
          id="is_active"
          v-model:checked="edit_form_state.is_active"
        />
      </a-form-item>
      <a-form-item
        v-for="item in edit_form_state.form_options"
        :id="item.code"
        :key="item.code"
        :label="item.label"
      >
        <a-select
          v-if="item.type==='select'"
          :id="item.code"
          v-model:value="edit_form_state.form_data[item.code]"
          size="large"
          class="sDash_fullwidth-select"
        >
          <a-select-option
            v-for="option in item.options"
            :key="option.value"
            :value="option.value"
            :selected="option.value===item.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
        <a-input
          v-else
          :id="item.code"
          v-model:value="edit_form_state.form_data[item.code]"
          :placeholder="item.label"
        />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="show_edit_form = false">
        İptal
      </a-button>
      <a-button
        type="primary"
        :loading="edit_form_submit_loading"
        @click="updateCargoInfo"
      >
        Kaydet
      </a-button>
    </template>
  </a-modal>
</template>

<script>

import { Main } from '@/view/styled'

export default {
  name: 'ContractedCargo',
  components: {
    Main,
  },
  data() {
    return {
      page_loading: false,
      create_form_open_loading: false,
      create_form_submit_loading: false,
      edit_form_open_loadings: [],
      edit_form_submit_loading: false,

      shipping_company_modules: [],
      configured_shipping_companies: [],

      show_create_form: false,

      create_form_state: {
        code: '',
        title: '',
        discount_rate: 0,
        can_cod: false,
        is_active: true,
        form_options: [],
        form_data: {},
      },

      show_edit_form: false,

      edit_form_state: {
        id: 0,
        title: '',
        discount_rate: 0,
        can_cod: false,
        is_active: true,
        form_options: [],
        form_data: {},
      },
    }
  },
  mounted() {
    this.getCargoCompany()
  },
  methods: {
    updateCargoInfo() {
      this.edit_form_submit_loading = true
      this.$restMethods.postForNoc('/edit-contracted-shipping-company-submit/' + this.edit_form_state.id, {
        title: this.edit_form_state.title,
        discount_rate: this.edit_form_state.discount_rate,
        can_cod: this.edit_form_state.can_cod,
        is_active: this.edit_form_state.is_active,
        data: this.edit_form_state.form_data,
      }).then(response => {
        if ( ! response.hasError()) {
          this.$message.success(response.getData().message)
          this.show_edit_form = false
          this.getCargoCompany()
        }
        this.edit_form_submit_loading = false
      })
    },
    editCargoModalForm(id) {
      this.edit_form_open_loadings[id] = true
      this.$restMethods.postForNoc('/edit-contracted-shipping-company-open/' + id).then(response => {
        if ( ! response.hasError()) {

          const data = response.getData()

          this.edit_form_state.id = data.id
          this.edit_form_state.title = data.title
          this.edit_form_state.discount_rate = data.discount_rate
          this.edit_form_state.can_cod = data.can_cod
          this.edit_form_state.is_active = data.is_active
          this.edit_form_state.form_options = data.form_options
          this.edit_form_state.form_data = data.form_data

          this.show_edit_form = true

        }
        this.edit_form_open_loadings[id] = false
      })
    },
    handleCreateCompany() {
      this.create_form_submit_loading = true
      this.$restMethods.postForNoc('/create-contracted-configured-shipping-company', {
        code: this.create_form_state.code,
        title: this.create_form_state.title,
        discount_rate: this.create_form_state.discount_rate,
        can_cod: this.create_form_state.can_cod,
        is_active: this.create_form_state.is_active,
        data: this.create_form_state.form_data,
      }, { show_success: true }).then(response => {
        if ( ! response.hasError()) {
          this.show_create_form = false
          this.create_form_state.code = ''
          this.create_form_state.title = ''
          this.create_form_state.discount_rate = 0
          this.create_form_state.can_cod = false
          this.create_form_state.is_active = true
          this.create_form_state.form_options = []
          this.create_form_state.form_data = {}
          this.getCargoCompany()
        }
        this.create_form_submit_loading = false
      })
    },
    handleCompanyChange() {
      this.create_form_state.form_options = []
      this.create_form_state.form_data = {}
      this.create_form_open_loading = true
      this.$restMethods.postForNoc('/get-shipping-company-form-options/' + this.create_form_state.code).then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.create_form_state.form_options = data.form_options
          this.create_form_state.form_data = data.form_data
        } else {
          this.create_form_state.code = ''
        }
        this.create_form_open_loading = false
      })
    },
    getCargoCompany() {
      this.page_loading = true
      this.$restMethods.postForNoc('/contracted-shipping-companies').then(response => {
        if ( ! response.hasError()) {
          const data = response.getData()
          this.shipping_company_modules = data.shipping_company_modules
          this.configured_shipping_companies = data.configured_shipping_companies
        }
        this.page_loading = false
      })
    },
  },
}
</script>
